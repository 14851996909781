import { url } from '@/config';
import request from '../axios'
export const getAction = (params) => request({
  url: 'https://gateway.zhihuihuoyun.cn/sys/user/getCurrentUserDeparts',
  // url: 'http://192.168.9.42:9978/sys/user/getCurrentUserDeparts',
  method: 'get',
  params: params
});

export const Login = (data) => request({
  url: 'https://gateway.zhihuihuoyun.cn/sys/appletLogin',
  // url: 'http://192.168.9.42:9978/sys/appletLogin',
  method: 'post',
  data: data
});
export const selectDepart = (data) => request({
  url: 'https://gateway.zhihuihuoyun.cn/sys/selectDepart',
  // url: 'http://192.168.9.42:9978/sys/selectDepart',
  method: 'put',
  data: data
});
//退出
export const logout = (data) => request({
  url: 'https://gateway.zhihuihuoyun.cn/sys/logout',
  // url: 'http://192.168.9.42:9978/sys/logout',
  method: 'post',
  data: data
});