<template>
  <div class="main">
    <div class="main">
      <a-form
        :form="form"
        class="user-layout-login"
        ref="formLogin"
        id="formLogin"
      >
        <a-tabs
          :activeKey="customActiveKey"
          :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
          @change="handleTabClick"
        >
          <a-tab-pane key="tab1" tab="账号密码登录">
            <a-form-item>
              <a-input
                size="large"
                v-decorator="[
                  'username',
                  validatorRules.username,
                  { validator: this.handleUsernameOrEmail },
                ]"
                type="text"
                placeholder="请输入帐户名"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>

            <a-form-item>
              <a-input
                v-decorator="['password', validatorRules.password]"
                size="large"
                type="password"
                autocomplete="false"
                placeholder="密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>

            <!-- <a-row :gutter="0">
            <a-col :span="16">
              <a-form-item>
                <a-input
                  v-decorator="['inputCode', validatorRules.inputCode]"
                  size="large"
                  type="text"
                  @change="inputCodeChange"
                  placeholder="请输入验证码"
                >
                  <a-icon slot="prefix" type="smile" :style="{ color: 'rgba(0,0,0,.25)' }" />
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="8" style="text-align: right">
              <img
                v-if="requestCodeSuccess"
                style="margin-top: 2px"
                :src="randCodeImage"
                @click="handleChangeCheckCode"
              />
              <img v-else style="margin-top: 2px" src="../../assets/checkcode.png" @click="handleChangeCheckCode" />
            </a-col>
          </a-row> -->
          </a-tab-pane>
        </a-tabs>

        <!-- <a-form-item>
          <a-checkbox
            v-decorator="[
              'rememberMe',
              { initialValue: true, valuePropName: 'checked' },
            ]"
            >自动登录</a-checkbox
          >
        </a-form-item> -->

        <a-form-item style="margin-top: 24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="loginBtn"
            @click.stop.prevent="handleSubmit"
            :disabled="loginBtn"
            >确定
          </a-button>
        </a-form-item>
      </a-form>
      <div>
        <a-modal
          :title="title"
          :width="450"
          :visible="visible"
          :closable="false"
          :maskClosable="false"
        >
          <template slot="footer">
            <a-button type="primary" @click="selectOk">确认</a-button>
          </template>

          <a-form>
            <a-form-item
              v-if="isMultiTenant"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 20 }"
              style="margin-bottom: 10px"
              :validate-status="validate_status1"
            >
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>您有多个租户，请选择登录租户</span>
                </template>
                <a-avatar style="backgroundcolor: #87d068" icon="gold" />
              </a-tooltip>

              <a-select
                @change="handleTenantChange"
                :class="{ 'valid-error': validate_status1 == 'error' }"
                placeholder="请选择登录租户"
                style="margin-left: 10px; width: 80%"
              >
                <a-icon slot="suffixIcon" type="gold" />
                <a-select-option
                  v-for="d in tenantList"
                  :key="d.id"
                  :value="d.id"
                >
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              v-if="isMultiDepart"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 20 }"
              style="margin-bottom: 10px"
              :validate-status="validate_status2"
            >
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>您有多个部门，请选择登录部门</span>
                </template>
                <a-avatar
                  style="backgroundcolor: rgb(104, 208, 203)"
                  icon="gold"
                />
              </a-tooltip>

              <a-select
                @change="handleDepartChange"
                :class="{ 'valid-error': validate_status2 == 'error' }"
                placeholder="请选择登录部门"
                style="margin-left: 10px; width: 80%"
              >
                <a-icon slot="suffixIcon" type="gold" />
                <a-select-option
                  v-for="d in departList"
                  :key="d.id"
                  :value="d.orgCode"
                >
                  {{ d.departName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import list from "./list/index";
import maps from "./list/map";
import category from "./list/category";
import { Login, selectDepart } from "../api/login";
import { Decrypt, Encrypt, encode64, decode64 } from "@/base64/utils.js";
import LoginSelectTenant from "./LoginSelectTenant";
export default {
  name: "RouteView",
  components: {
    LoginSelectTenant,
    list,
    maps,
    category
  },
  data() {
    return {
      visible: false,
      isMultiDepart: false,
      departList: [],

      isMultiTenant: false,
      tenantList: [],

      username: "",
      orgCode: "",
      tenant_id: "",

      validate_status1: "",
      validate_status2: "",
      activeName: "1",
      customActiveKey: "tab1",
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      encryptedString: {
        key: "",
        iv: "",
      },
      state: {
        time: 60,
        smsSendBtn: false,
      },
      validatorRules: {
        username: {
          rules: [
            { required: true, message: "请输入用户名!" },
            { validator: this.handleUsernameOrEmail },
          ],
        },
        password: {
          rules: [
            { required: true, message: "请输入密码!", validator: "click" },
          ],
        },
        mobile: { rules: [{ validator: this.validateMobile }] },
        captcha: { rule: [{ required: true, message: "请输入验证码!" }] },
        inputCode: { rules: [{ required: true, message: "请输入验证码!" }] },
      },
      verifiedCode: "",
      inputCodeContent: "",
      inputCodeNull: true,
      currentUsername: "",
      currdatetime: "",
      randCodeImage: "",
      requestCodeSuccess: false,
    };
  },
  computed: {
    title() {
      if (this.isMultiDepart && this.isMultiTenant) {
        return "请选择租户和部门";
      } else if (this.isMultiDepart && !this.isMultiTenant) {
        return "请选择部门";
      } else if (!this.isMultiDepart && this.isMultiTenant) {
        return "请选择租户";
      }
    },
  },
  created() {},
  methods: {
    clear() {
      this.departList = [];
      this.tenantList = [];
      this.visible = false;
      this.validate_status1 = "";
      this.validate_status2 = "";
    },
    bizDepart(loginResult) {
      let multi_depart = loginResult.multi_depart;
      //0:无部门 1:一个部门 2:多个部门
      if (multi_depart == 0) {
        this.$notification.warn({
          message: "提示",
          description: `您尚未归属部门,请确认账号信息`,
          duration: 3,
        });
        this.isMultiDepart = false;
      } else if (multi_depart == 2) {
        this.visible = true;
        this.isMultiDepart = true;
        this.departList = loginResult.departs;
      } else {
        this.isMultiDepart = false;
      }
    },
    bizTenant(ids) {
      if (!ids || ids.length == 0) {
        this.isMultiTenant = false;
      } else if (ids.indexOf(",") < 0) {
        this.tenant_id = ids;
        this.isMultiTenant = false;
      } else {
        this.visible = true;
        this.isMultiTenant = true;
        getAction("/sys/tenant/queryList", { ids: ids }).then((res) => {
          this.tenantList = res.result;
        });
      }
    },
    show(loginResult) {
      this.clear();
      this.bizDepart(loginResult);
      let user = Vue.ls.get(USER_INFO);
      this.username = user.username;
      let ids = user.relTenantIds;
      this.bizTenant(ids);

      if (this.visible === false) {
        this.$store.dispatch("saveTenant", this.tenant_id);
        this.$emit("success");
      }
    },
    departResolve(){
        return new Promise((resolve, reject)=>{
          if(this.isMultiDepart===false){
            resolve();
          }else{
            let obj = {
              orgCode:this.orgCode,
              username:this.username
            }
            putAction("/sys/selectDepart",obj).then(res=>{
              if(res.success){
                const userInfo = res.result.userInfo;
                Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000);
                this.$store.commit('SET_INFO', userInfo);
                //console.log("---切换组织机构---userInfo-------",store.getters.userInfo.orgCode);
                resolve();
              }else{
                this.requestFailed(res)
                this.$store.dispatch('Logout');
                reject();
              }
            })
          }
        })
      },
    selectOk() {
      var routeUrl = sessionStorage.routeUrls
      if (this.isMultiTenant && !this.tenant_id) {
        this.validate_status1 = "error";
        return false;
      }
      if (this.isMultiDepart && !this.orgCode) {
        this.validate_status2 = "error";
        return false;
      }
      selectDepart({
        username: this.username,
        orgCode: this.orgCode,
      }).then((res) => {
        if (res.data.success == true) {
          this.$router.push({ path: routeUrl });
        }
      });
    },
    handleTenantChange(e) {
      this.validate_status1 = "";
      this.tenant_id = e;
    },
    handleDepartChange(e) {
      this.validate_status2 = "";
      this.orgCode = e;
    },
    isBase64(str) {
      try {
        if (null == str || str === "" || str.trim() === "") {
          return false;
        } else if (encode64(decode64(str)) == str) {
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    handleSubmit() {
      if(sessionStorage.getItem('routeUrl')==null){
        sessionStorage.setItem("routeUrls",'/');
      }else{
        sessionStorage.setItem("routeUrls",sessionStorage.getItem('routeUrl'));
      }
      let that = this;
      let loginParams = {};
      that.loginBtn = true;
      // 使用账户密码登录
      if (that.customActiveKey === "tab1") {
        that.form.validateFields(
          ["username", "password"],
          { force: true },
          (err, values) => {
            if (!err) {
              var isBase64 = this.isBase64(loginParams.password);
              if (!isBase64) {
                loginParams.password = Encrypt(values.password);
                //字符串转base64
                loginParams.password = encode64(loginParams.password);
              }
              loginParams.username = values.username;
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              //loginParams.password = md5(values.password)
              //loginParams.password = encryption(values.password,that.encryptedString.key,that.encryptedString.iv)
              // loginParams.password = values.password
              loginParams.remember_me = values.rememberMe;
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              loginParams.captcha = that.inputCodeContent;
              loginParams.checkKey = that.currdatetime;
              Login(loginParams)
                .then((res) => {
                  if (res.data.success == true) {
                    this.username = res.data.result.userInfo.username
                    // console.log(res.data.result.userInfo.username)
                    localStorage["tokens"] = res.data.result.token;
                    localStorage["username"] = res.data.result.userInfo.username;
                    if(res.data.result.multi_depart==1){
                      this.orgCode=res.data.result.departs[0].orgCode
                      this.selectOk()
                    }
                    this.show(res.data.result);
                  } else {
                    this.$notification["error"]({
                      message: "登录失败",
                      description: res.data.message,
                      duration: 4,
                    });
                    that.loginBtn = false;
                  }
                })
                .catch((err) => {});
            } else {
              that.loginBtn = false;
            }
          }
        );
        // 使用手机号登录
      } else {
        that.form.validateFields(
          ["mobile", "captcha", "rememberMe"],
          { force: true },
          (err, values) => {
            if (!err) {
              loginParams.mobile = values.mobile;
              loginParams.captcha = values.captcha;
              loginParams.remember_me = values.rememberMe;
              that
                .PhoneLogin(loginParams)
                .then((res) => {
                  console.log(res.result);
                  this.$refs.loginSelect.show(res.result);
                })
                .catch((err) => {
                  that.requestFailed(err);
                });
            }
          }
        );
      }
    },
    loginSuccess() {
      this.$router.push({ path: "/dashboard/analysis" }).catch(() => {
        console.log("登录跳转首页出错,这个错误从哪里来的");
      });
      this.$notification.success({
        message: "欢迎",
        description: `${timeFix()}，欢迎回来`,
      });
    },
    loginSelectOk() {
      this.loginSuccess();
    },
    requestFailed(err) {
      this.$notification["error"]({
        message: "登录失败",
        description:
          ((err.response || {}).data || {}).message ||
          err.message ||
          "请求出现错误，请稍后再试",
        duration: 4,
      });
      this.loginBtn = false;
    },
    goData() {
      window.open("https://www.kancloud.cn/smallwei/avue-doc");
    },
    handleSelect(key) {
      this.activeName = key;
    },
  },
};
</script>
<style lang="scss">
.user-layout-login button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  width: 100%;
}
input {
  margin: 0;
  color: black !important;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: white !important;
  border-color: #d9d9d9 !important;
}
.index {
  height: 100%;
  .header {
    position: relative;
    padding: 0;
    height: auto !important;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    .title {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 34px;
      color: rgb(0, 186, 255);
      font-weight: 500;
    }
    .title small {
      font-size: 18px;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
}
</style>