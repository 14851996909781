<template>
  <div class="index">
    <div class="header">
      <img
        :src="`${publicPath}img/nav-img.png`"
        alt=""
        width="100%"
        height="220px"
      />
      <div class="logo">
        <span>{{ $website.name }}</span>
        <small>{{ $website.subName }}</small>
      </div>
      <navs @change="handleChange"></navs>
    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>
<script>
import navs from "./nav/index";
export default {
  name: "index",
  components: {
    navs,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_PATH,
    };
  },
  mounted() {
    // this.$notify({
    //   dangerouslyUseHTMLString: true,
    //   title: "可视化数据大屏（演示环境-请勿放生产数据）",
    //   message: `点击购买`,
    //   duration: 0,
    //   onClick: () => {
    //     window.open("https://avuejs.com/views/pay/");
    //   },
    // });
  },
  methods: {
    handleChange(item, index) {
      var token = localStorage.getItem("tokens");
      if (index == 0) {
        this.$router.push({ path: "/", query: { token } });
      } else {
        this.$router.push({ path: item.path });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/list.scss";
.index {
  height: 100%;
  .header {
    position: relative;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
  .content {
    overflow-x: hidden;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 20px;
  }
}
</style>